<template>
  <div class="">
    <div class="bb-container bb-container--config">
      <no-ssr>
        <portal-target name="alerts_configuration"></portal-target>
      </no-ssr>
    </div>

    <div class="g-user-sidebar">
      <div class="bb-container bb-container--config">
        <div class="g-user-sidebar__grid">
          <left-menu active="my-list"></left-menu>
          <div class="g-user-sidebar__content">
            <h2 class="g-user-sidebar__head">{{ $t("settings.myList") }}</h2>
            <div class="g-user-sidebar__main g-user-sidebar__main--lista">
              <div class="config-data">
                <div class="config-data__form">
                  <div class="bb-form-fieldset">
                    <div class="bb-form-item">
                      <div class="bb-form-label">{{ $t("generic.listName") }}</div>
                      <input
                        v-model="title"
                        class="bb-form"
                        type="text"
                        maxlength="256"
                        name="title"
                        id="title"
                        required=""
                        @blur="nameBlur"
                        :placeholder="$t('settings.listNamePlaceholder')"
                      />
                      <div class="bb-form-error" v-show="nameHasError">
                        {{ $t("generic.field_is_required") }}
                      </div>
                    </div>
                    <div class="bb-form-item">
                      <div class="bb-form-label">{{ $t("settings.welcomeMessage") }}</div>
                      <textarea
                        v-model="welcomeMessage"
                        class="bb-form-textarea"
                        type="text"
                        maxlength="256"
                        row="4"
                        name="welcomeMessage"
                        id="welcomeMessage"
                        required=""
                      />
                    </div>
                  </div>
                  <div class="bb-form-fieldset" v-if="list.type === 'baby-registry' || list.type === 'babyshower'">
                    <h3 class="bb-form-fieldset__header">{{ $t("settings.babyInfo") }}</h3>

                    <div class="bb-form-item">
                      <div class="bb-form-label">{{ $t("settings.babyName") }}</div>
                      <input
                        v-model="childName"
                        class="bb-form"
                        type="text"
                        maxlength="256"
                        name="childName"
                        id="childName"
                        required=""
                        :placeholder="$t('settings.babyNamePlaceholder')"
                        @blur="childNameBlur"
                      />
                      <div class="bb-form-error" v-show="childNameHasError">
                        {{ $t("generic.field_is_required") }}
                      </div>
                    </div>

                    <div class="bb-form-item">
                      <div class="bb-form-label">{{$t("mylist.childBirthday")}}</div>
                      <date-picker
                        id="childBirthday"
                        name="childBirthday"
                        v-model="childBirthdayIntern"
                      ></date-picker>
                    </div>
                  </div>
                  <div class="bb-form-fieldset" v-if="user && user.isMainUser">
                    <h3 class="bb-form-fieldset__header">{{ $t("settings.collaborators") }}</h3>
                    <!-- Agregar colaborador  -->
                    <div  v-if="user && !hasColaborator && !pendingInvitation">
                      <template>
                        <div class="bb-form-item">
                          <div class="bb-form-label">
                            {{ $t("settings.collaboratorsSubtitle") }}
                          </div>
                          <input
                            v-model="collaboratorName"
                            type="text"
                            class="bb-form"
                            :placeholder="$t('onboarding.form_name_placeholder')"
                            name="collaboratorName"
                            id="collaboratorName"
                          />
                          <div class="bb-form-error" v-show="collaboratorNameHasError">
                            {{ $t("generic.field_is_required") }}
                          </div>
                        </div>
                        <div class="bb-form-item">
                          <input
                            v-model="collaboratorEmail"
                            type="text"
                            class="bb-form"
                            :placeholder="$t('onboarding.form_email_placeholder')"
                            name="collaboratorEmail"
                            id="collaboratorEmail"
                            @input="handleInput" />
                          <div class="bb-form-error" v-show="collaboratorEmailHasError">
                            {{ $t("generic.field_is_required") }}
                          </div>
                          <div class="bb-form-error" v-show="collaboratorEmailHasExist">
                            {{ $t("settings.collaboratorErrorEmailInUseAnon")}}
                          </div>
                          <div class="bb-form-error" v-show="collaboratorEmailFormat">
                            {{ $t("generic.emailWrongFormat") }}
                          </div>
                        </div>
                      </template>
                    </div>
                    <!-- Borrar colaborador  -->
                    <template  v-if="user && hasColaborator">
                      <div class="bb-form-label">
                        {{ $t("mylist.widgetCollaboratorCurrentCollaboratorTitle") }}
                      </div>
                      <div class="colaborador">
                        <div class="colaborador__left">
                          <div class="colaborador__nombre">{{ collaboratorName }}</div>
                          <div class="colaborador__email">{{ collaboratorEmail }}</div>
                        </div>
                        <button class="colaborador__delete" @click="deleteCollaborator">
                          <i class="uil uil-trash" v-tooltip="'Borrar colaborador'"></i>
                        </button>
                      </div>
                    </template>
                    <template  v-else-if="user && pendingInvitation">
                      <div class="bb-form-label">
                        {{ $t("settings.collaboratorPendingInvitation") }}
                      </div>
                      <div class="colaborador">
                        <div class="colaborador__left">
                          <div class="colaborador__nombre">{{ invitationText }}</div>
                        </div>
                        <button class="colaborador__delete" @click="deleteInvitation">
                          <i class="uil uil-trash" v-tooltip="$t('settings.collaboratorDelete')"></i>
                        </button>
                      </div>
                    </template>
                  </div>
                  <div v-if="user.stripeAccountId">
                    <div class="bb-form-fieldset">
                      <h3 class="bb-form-fieldset__header">{{ $t("settings.listPassword") }}</h3>
                      <div class="bb-form-item">
                        <div class="bb-form-label">{{ $t("login.password") }}</div>
                        <input
                          v-model="listPassword"
                          class="bb-form"
                          type="text"
                          maxlength="256"
                          name="listPassword"
                          id="listPassword"
                        />
                        <div class="bb-form-error" v-show="passwordHasError">
                          {{ $t("generic.field_is_required") }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="config-data__actions">
                  <loading-button v-if="saving"></loading-button>
                  <button
                    v-else
                    class="button button--primary button--block"
                    @click.prevent="save"
                    :disabled="disabled"
                  >
                    {{ $t("generic.save") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <alert v-show="showAlertLocal" @retry="save()" />
      </div>
    </div>
  </div>
</template>

<script>
import { VTooltip } from "floating-vue";
import "floating-vue/dist/style.css";

import { mapGetters } from "vuex";
import LoadingButton from "../../components/LoadingButton";
import ConfigurationAlert from "./components/ConfigurationAlert";
import NoSSR from "vue-no-ssr";
import LeftMenu from "./components/LeftMenu";
import moment from "moment";
import DatePicker from "../../components/DatePicker";
import CanGetOwnerList from "../../Mixins/CanGetOwnerList";

moment.locale("moment_es");

export default {
  name: "ConfigurationMyListPage",
  mixins: [CanGetOwnerList],
  components: {
    LoadingButton,
    "no-ssr": NoSSR,
    alert: ConfigurationAlert,
    LeftMenu,
    DatePicker
  },
  metaInfo() {
    return {
      title: this.$t("generic.settings") + " | HelloBB"
    };
  },
  data() {
    return {
      title: "",
      welcomeMessage: "",
      childBirthdayIntern: null,
      loading: false,
      errorMsg: null,
      saving: false,
      notDecided: false,
      collaboratorEmailExist: false,
      collaboratorEmailFormat: false,
      childName: "",
      collaboratorName: "",
      collaboratorEmail: "",
      invitationText: "",
      hasColaborator: false,
      pendingInvitation: false,
      listPassword: "",
    };
  },
  directives: {
    tooltip: VTooltip
  },
  computed: {
    ...mapGetters({
      list: "ownerList",
      showAlertKey: "showAlertKey",
      showAlert: "showAlert",
      user: "user"
    }),
    childNameHasError() {
      if (this.notDecided) {
        return false;
      }
      return this.childName === "" && this.childNameDirty;
    },
    collaboratorEmailHasError() {
      return this.collaboratorName.trim() !== "" && this.collaboratorEmail.trim() === "" && !this.collaboratorEmailExist;
    },

    collaboratorEmailHasExist() {
      return this.collaboratorEmailExist
    },
    collaboratorNameHasError() {
      return this.collaboratorEmail.trim() !== "" && this.collaboratorName.trim() === "";
    },
    childBirthday: {
      get() {
        if (!this.user) return null;
        const birthday = this.user.childBirthday;
        if (birthday === "0001-01-01T00:00:00" || birthday === "") {
          return null;
        }
        return moment(birthday).format("yyyy-MM-DD");
      },
      set(value) {
        this.childBirthdayIntern = value;
      }
    },
    showAlertLocal() {
      return this.showAlertKey === this.key && this.showAlert;
    },
    key() {
      return "configuration_page";
    },
    disabled() {
      return false;
      const isNameInvalid = this.name === "";

      const isCollaboratorInvalid = !this.collaboratorName || !this.collaboratorEmail || this.collaboratorEmailFormat;

      return isNameInvalid || isCollaboratorInvalid;
    },
    nameHasError() {
      return this.name === "" && this.nameDirty;
    },
    passwordHasError() {
      return this.listPassword === "" && this.passwordDirty;
    },
    passwordDirty() {
      return this.listPasswordDirty;
    }
  },
  methods: {
    childNameBlur() {
      this.childNameDirty = true;
      if (this.childName !== "") this.notDecided = false;
    },
    getImgUrl() {
      return process.env.URL_IMG_USER + this.user.photo;
    },
    async save() {
      this.saving = true;
      try {
        if (this.list.type === "baby-registry" || this.list.type === "babyshower") {  
          if (this.childBirthdayIntern) {
            await this.$store.dispatch("updateChildBirthday", this.childBirthdayIntern);
            this.$store.commit("updateUserField", {
              field: "childBirthday",
              value: this.childBirthdayIntern
            });
          }
          const childName = !this.childName ? "TBD" : this.childName;
          if(childName)
          {
            await this.$store.dispatch("updateChildName", childName);
            this.$store.commit("updateUserField", {
              field: "childName",
              value: childName
            });
          }
        }

        await this.$store.dispatch("updateListTitle", {
          list: this.list,
          title: this.title
        });
        if (!this.collaboratorName && !this.collaboratorEmail) {
          //No hacer nada
        } else if (!this.collaboratorName || !this.collaboratorEmail) {
          //No hacer nada
        } else if (this.hasColaborator){
          //No hacer nada
        } else {
          const collaboratorData = {
            email: this.collaboratorEmail,
            name: this.collaboratorName
          };
          let res = await this.$store.dispatch("sendInvitation", collaboratorData);
          if (res.status === 200) {
            window.mixpanel.track("Invitar col·laborador", {
              "Nombre del colaborador": this.collaboratorName,
              "Email del colaborador": this.collaboratorEmail,
            });
            this.pendingInvitation = true;
            this.invitationText = this.collaboratorName + " (" + this.collaboratorEmail + ")";
          } else {
            if (res.status == 409) {
              this.$alert.error(this.$t("settings.collaboratorErrorEmailInUseAnon"), this.key);
              return;
            } else {
              this.$alert.error(this.$t("generic.errorNoSave"), this.key);
              return;
            }
          }
          this.collaboratorName = '';
          this.collaboratorEmail = '';
        }
        if (this.welcomeMessage) {
          await this.$store.dispatch("updateWelcomeMessage", this.welcomeMessage);
        }
        if (this.listPassword) {
          await this.$store.dispatch("patchListPwd", this.listPassword);
        }
      } catch (error) {
        console.log(error);
        this.saving = false;
        if (error.response && error.response.status === 409) {
          this.collaboratorEmailExist = true;
          this.$alert.error(this.$t("settings.collaboratorErrorEmailInUse", { email: this.collaboratorEmail }), this.key);
        } else {
          this.$alert.error(this.$t("generic.errorNoSave"), this.key);
        }
        return;
      }
      await this.$store.dispatch("getOwnerList");
      this.$alert.success(this.$t("generic.saveMessage"), this.key);
      this.saving = false;
    },
    nameBlur() {
      this.nameDirty = true;
    },
    async deleteCollaborator() {
      try {
        let partner = this.user.partner;
        var res = await this.$store.dispatch("deleteCollaborator");
        if (res.status === 200) {
          this.$alert.success(this.$t("settings.collaboratorDeleteSucess"), this.key);
          window.mixpanel.track("Eliminar col·laborador", {
            "Nombre del colaborador": partner.name,
            "Email del colaborador": partner.email
          });
          this.hasColaborator = false;
          this.collaboratorName = "";
          this.collaboratorEmail = "";
        } else {
          this.$alert.error(this.$t("settings.collaboratorDeleteError"), this.key);
        }
      } catch (error) {
        this.$alert.error(this.$t("settings.collaboratorDeleteError"), this.key);
      }
    },
    async deleteInvitation() {
      try {
        let invitationText = this.user.invitationText;
        var res = await this.$store.dispatch("deleteInvitation");
        if (res.status === 200) {
          this.$alert.success(this.$t("settings.collaboratorInvitationDeleteSucess"), this.key);
          window.mixpanel.track("Eliminar invitació", {
            "Invitation": invitationText
          });
          this.pendingInvitation = false;
        } else {
          this.$alert.error(this.$t("settings.collaboratorInvitationDeleteError"), this.key);
        }
      } catch (error) {
        this.$alert.error(this.$t("settings.collaboratorInvitationDeleteError"), this.key);
      }
    },
    validateEmailFormat(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    handleInput() {
      this.collaboratorEmailExist = false;
      this.collaboratorEmailFormat = !this.validateEmailFormat(this.collaboratorEmail);
    },
  },
  mounted() {
    console.log(this.user)
    this.title = this.list.title;
    this.welcomeMessage = this.list.welcomeMessage;
    this.listPassword = this.list.password;
    this.childBirthdayIntern = this.list.childBirthday;
    if (this.list.childName === "TBD") {
      this.childName = "";
      this.notDecided = true;
    }
    else this.childName = this.list.childName;
    if (this.user.partner) {
      this.hasColaborator = true;
      this.collaboratorName = this.user.partner.name;
      this.collaboratorEmail = this.user.partner.email;
    }
    if (this.user.invitationStatus === 'pending' && this.user.invitationText != null) {
      this.pendingInvitation = true;
      this.invitationText = this.user.invitationText;
    }
    if (this.user.invitationStatus === 'accepted' && this.user.invitationText != null) {
      this.pendingInvitation = false;
    }

    console.log(this.hasColaborator)
    console.log(this.pendingInvitation)
  }
};
</script>
